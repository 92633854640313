import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/Home.css";
import instructionVideo from "./image/Instruction.mp4"; // นำเข้าวิดีโอ
import api from "./api";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataMonitor, setAlldataMonitor] = useState({});
  const [contestants, setContestants] = useState([]);
  const [countdown, setCountdown] = useState(1);
  const [isStopped, setIsStopped] = useState(false);
    const [timecountdown, setTimecountdown] = useState(1000);
  const email = location.state?.email; // รับ email จาก Login
  const username = location.state?.username; // รับ username จาก Home
  const DirectorID = location.state?.DirectorID;

  const handleButtonClick = () => {
    navigate("/list-conts", { state: { email, username, DirectorID } });
  };

  const getAllmonitor = async () => {
    try {
      const response = await api.post("/getAllmonitor");
      setAlldataMonitor(response.data.data);
      console.log("Updated dataMonitor:", response.data.data);
    } catch (error) {
      console.error("Error fetching monitor data:", error);
    }
  };


  const setDelay = async () => {
    console.log("data monitor: ",dataMonitor["delay"])
    const dataDelay = dataMonitor["delay"];
    const timedelay = dataDelay * 1000;
    setCountdown(dataDelay);
    setTimecountdown(timedelay);
  }

  const Admin_control = async () => {
    console.log(dataMonitor); // ตรวจสอบข้อมูลทั้งหมดใน dataMonitor
    const monitorData = dataMonitor[DirectorID];
    console.log(monitorData);

    if (monitorData === "Instruction") {
      console.log(`DirectorID: ${DirectorID} is currently set to Instruction`);
      setDelay();
    } else {
      console.log(
        `DirectorID: ${DirectorID} is currently monitoring----- : ${monitorData} , isStopped ${isStopped}`
      );
      setIsStopped(true);

      console.log("isstopped: ",isStopped)
      // setIsTimer(true);
      if (monitorData === "Dashboard") {
        setTimeout(() => {
          handleImagesClick();
        }, timecountdown);
      } else if (monitorData === "Instruction") {
        setTimeout(() => {
          handleAdmincontrolintruction();
        }, timecountdown);
      } else {
        setTimeout(() => {
          handleAdmincontrol(monitorData);
        }, timecountdown);
      }
    }
  };

  const setMonitor = async (Contsid) => {
    try {
      const response = await api.post(`/setmonitor`, {
        userID: Contsid,
        DirID: DirectorID,
      });
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  const handleImagesClick = () => {
    navigate("/list-conts", { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  const handleAdmincontrolintruction = () => {
    navigate(`/home-comming`, { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  useEffect(() => {
    console.log("isStopped: -----------------------------", isStopped);
    getConts(); // Call getConts when the component mounts
    setMonitor("Instruction");
  }, []);

  const getConts = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data); // Assuming response.data.data contains contestant list
    } catch (error) {
      console.error(
        "Login error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleAdmincontrol = (id) => {
    if (id) {
      navigate(`/vote/`, {
        state: { username, id: id, contestants, DirectorID },
      });
    }
  };

  useEffect(() => {
    if (!isStopped) {
      const intervalId = setInterval(() => {
        getAllmonitor();
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isStopped]);

  useEffect(() => {
    if (!isStopped && Object.keys(dataMonitor).length !== 0 && DirectorID) {
      Admin_control();
    }
  }, [dataMonitor, DirectorID, isStopped]);

  return (
    <div className="container p-5">
      <div className="video-container mb-4 mt-2">
        {/* วิดีโอที่เล่นซ้ำ */}
        <video
          src={instructionVideo}
          className="img-fluid"
          autoPlay
          loop
          muted
          controls={false}
          style={{ maxWidth: "750px", maxHeight: "750px" }}
        />
      </div>
      <button className="info-button" onClick={handleButtonClick}>
        เข้าสู่หน้าการประเมิน
      </button>
    </div>
  );
};

export default Home;
