import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Payment from "./payment";
import ConstPage from "./contboard"

const PublishDashboard = () => {
  const [activeTab, setActiveTab] = useState("Payment");

  const renderContent = () => {
    switch (activeTab) {
      case "Payment":
        return <Payment />;
      case "ContScore":
        return <ConstPage />;
      default:
        return (
          <div className="text-center mt-5">
            <h4 className="text-muted">Select a tab to view content.</h4>
          </div>
        );
    }
  };

  return (
    <div className="container mt-5">
      <div className="row mb-4">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div className="btn-group">
              <button
                className={`btn ${
                  activeTab === "Payment"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => setActiveTab("Payment")}
              >
                Payment
              </button>
              <button
                className={`btn ${
                  activeTab === "ContScore"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => setActiveTab("ContScore")}
              >
                ContScore
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Body Content */}
      <div className="row">
        <div className="col-12">
          <div className="p-4 border rounded shadow-sm bg-light">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishDashboard;
