import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // นำเข้า BrowserRouter
import './App.css';
import Login from './components/page/Login';
import Home from './components/page/Home';
import ListConts from './components/page/ListConts';
import Vote from './components/page/Vote';
import AdminHome from './components/page/admin';
import DetailScore from './components/page/component-admin/Detailscore';

function App() {

  return (
    <Router> {/* ห่อหุ้ม Routes ภายใน Router */}
      <div className="App">
        <Routes>
          <Route path="/home-comming" element={<Home />} />
          <Route path="/" element={<Login />} />
          <Route path="/list-conts" element={<ListConts />} />
          <Route path="/vote/" element={<Vote />} />
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/detail" element={<DetailScore />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
