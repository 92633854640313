import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const PaymentDashboard = () => {
  const [allPayments, setAllPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [resultPrice, setResultPrice] = useState();

  const getDataAllPayments = async () => {
    try {
      const response = await api.post(`/getAlldataPayment`);
      const allData = response.data.data;
      // กรองเฉพาะคีย์ที่เป็นตัวเลข
      const paymentsArray = Object.entries(allData)
        .filter(([key]) => !isNaN(key)) // กรองเฉพาะคีย์ที่เป็นตัวเลข
        .map(([, value]) => value); // แปลงกลับเป็น array ของ value
      const resultprice = response.data.data.resultPrice;
      setResultPrice(resultprice);
      setAllPayments(paymentsArray);
      setFilteredPayments(paymentsArray);
    } catch (error) {
      console.error("Error fetching payments data:", error);
    }
  };

  useEffect(() => {
    getDataAllPayments();
  }, []);

  const handleFilter = (status) => {
    if (status === "All") {
      setFilteredPayments(allPayments);
    } else {
      setFilteredPayments(
        allPayments.filter((payment) => payment.Status === status)
      );
    }
  };

  const handleVerified = async (payment) => {
    try {
      // เรียก API เพื่อเพิ่มข้อมูลใน `usetlistvote`
      const voteResponse = await api.post(`/setlistvote`, {
        contID: payment.contID,
        fullName: payment.fullName,
        email: payment.email,
        phonenumber: payment.phonenumber,
        score: payment.score,
        price: payment.price,
        dataIndex: payment.dataId,
      });

      if (voteResponse.status !== 200) {
        throw new Error("Failed to add data to usetlistvote");
      }

      if (voteResponse.status === 200) {
        const updateResponse = await api.post(`/updateDataSuccess`, {
          Status: "Verified",
          dataIndex: payment.dataId,
          price: payment.price,
        });

        const responsemessage = await api.post("/reportVoteVerify", {
          ToEmail: payment.email,
          ContID: payment.contID,
          Score: payment.score,
          Price: payment.price,
        });
        console.log("Payment successfully verified");
        alert(responsemessage.data.message);
        if (updateResponse.status !== 200) {
          throw new Error("Failed to update data status to Verified");
        }
      }
    } catch (error) {
      console.error("Error in handleVerified:", error);
      alert("Payment is verified");
    }
  };

  const handleRejected = async (payment) => {
    try {
      // เรียก API เพื่ออัปเดตคะแนนใน contestant
      const voteResponse = await api.post(`/updataScoreDataCont`, {
        contID: payment.contID,
        score: payment.score,
        dataIndex: payment.dataId,
      });

      if (voteResponse.status !== 200) {
        throw new Error("Failed to update contestant score");
      }
      // เรียก API เพื่ออัปเดตสถานะเป็น "Rejected"
      if (voteResponse.status === 200) {
        const response = await api.post(`/updateDataRejected`, {
          Status: "Rejected",
          dataIndex: payment.dataId,
          price: payment.price,
        });

        if (response.status !== 200) {
          throw new Error("Failed to update data status to Rejected");
        }
      }
      const responseMessage = voteResponse.data;
      console.log("Payment successfully rejected and score updated.");
      console.log("Message: ", responseMessage.message);
      alert("Payment has been rejected successfully.");
    } catch (error) {
      console.error("Error in handleRejected:", error.message);
      alert("Status is not Verified");
    }
  };

  const handleShowModal = (payment) => {
    setSelectedPayment(payment);
    setShowModal3(true);
  };

  return (
    <div className="container mt-5">
      <div className="row mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="btn-group">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleFilter("All")}
            >
              All Data
            </button>
            <button
              className="btn btn-outline-warning"
              onClick={() => handleFilter("Waiting")}
            >
              Waiting
            </button>
            <button
              className="btn btn-outline-success"
              onClick={() => handleFilter("Verified")}
            >
              Verified
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
          </div>
          <h2 className="text-center mb-4 text-primary">Payment Dashboard</h2>
          <p className="fw-bold text-primary">
            Total Amount: {resultPrice || 0} บาท
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="p-4 bg-light border rounded shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Payment List</h5>
              {/* ปุ่ม Refresh */}
              <button
                className="btn btn-outline-primary"
                onClick={getDataAllPayments}
              >
                Refresh
              </button>
            </div>
            {filteredPayments.map((payment, index) => (
              <div
                key={index}
                className="d-flex justify-content-between p-3 mb-4 border border-grey rounded bg-white shadow-sm align-items-center"
              >
                <div className="my-2 col-5">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <strong>Name:</strong> {payment.fullName}
                    </div>
                    <div>
                      <strong>Phone:</strong> {payment.phonenumber}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <strong>Email:</strong>{" "}
                      <span className="text-muted">{payment.email}</span>
                    </div>
                    <div>
                      <strong>contID:</strong>{" "}
                      <span className="text-muted">{payment.contID}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <strong>Price:</strong>{" "}
                      <span className="text-primary">{payment.price} บาท</span>
                    </div>
                    <div>
                      <strong>Score:</strong>{" "}
                      <span className="text-success">
                        {payment.score} คะแนน
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <strong>Date:</strong> {payment.dateTime}
                    </div>
                    <div>
                      <p>
                        <strong>Status:</strong>{" "}
                        <span
                          className={`badge ${
                            payment.Status === "Verified"
                              ? "bg-success"
                              : payment.Status === "Rejected"
                              ? "bg-danger"
                              : "bg-warning"
                          }`}
                        >
                          {payment.Status}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="my-2 col-4">
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-info text-white me-2"
                      onClick={() => handleShowModal(payment)}
                    >
                      View Image
                    </button>
                    <button
                      className="btn btn-success me-2"
                      onClick={() => handleVerified(payment)}
                    >
                      Verify
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleRejected(payment)}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedPayment && (
        <div
          className={`modal fade ${showModal3 ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5 className="modal-title">Payment Image</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal3(false)}
                ></button>
              </div>
              <div className="modal-body">
                <img
                  src={selectedPayment.imagesURL}
                  alt={`Payment ${selectedPayment.dataId}`}
                  className="img-fluid rounded"
                />
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowModal3(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDashboard;
