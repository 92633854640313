import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const Monitor = () => {
  const [director, setDirector] = useState([]);
  const [contestants, setContestants] = useState([]);
  const [selectedContestant, setSelectedContestant] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [dataMonitor, setAlldataMonitor] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState(1);

  const getDirector = async () => {
    try {
      const response = await api.post("/getAllDirectors");
      setDirector(response.data.data);
    } catch (error) {
      console.error(
        "get All Data error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const stationMapping = {
    "หมวด 12 อ คิดสร้างสรรค์และ การตอบคำถามและทัศนคติ (ส่วนบุคคล)": "Station1",
    หมวดเดินแบบชุดราตรี: "Station2",
  };

  const getContestants = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data);
    } catch (error) {
      console.error("Error fetching contestants:", error);
    }
  };

  const getAllmonitor = async () => {
    try {
      const response = await api.post("/getAllmonitor");
      setAlldataMonitor(response.data.data);
    } catch (error) {
      console.error("Error fetching monitor data:", error);
    }
  };

  const setMonitor = async (dirID, userID) => {
    try {
      await api.post(`/setmonitor`, {
        userID: userID,
        DirID: dirID,
      });
      console.log(`Monitor set for DirID: ${dirID} with userID: ${userID}`);
    } catch (error) {
      console.error("Error setting monitor data:", error);
    }
  };

  useEffect(() => {
    getDirector();
    getContestants();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllmonitor();
    }, 300);
    return () => clearInterval(intervalId);
  }, []);

  const handleContestantChange = async (selectedID) => {
    setSelectedContestant(selectedID);
    if (selectedID) {
      for (const dir of director.filter((dir) => dir.ID !== "A001")) {
        await setMonitor(dir.ID, selectedID);
      }
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredContestants = contestants.filter((contestant) =>
    contestant.ID ? contestant.ID.toString().includes(searchTerm) : false
  );

  const handleInstructionClick = async () => {
    // When button is clicked, send "Instruction" instead of selectedContestant
    for (const dir of director.filter((dir) => dir.ID !== "A001")) {
      await setMonitor(dir.ID, "Instruction");
    }
  };

  const handleDashboardClick = async () => {
    // When button is clicked, send "Instruction" instead of selectedContestant
    for (const dir of director.filter((dir) => dir.ID !== "A001")) {
      await setMonitor(dir.ID, "Dashboard");
    }
  };

  const handlesetStation = async (event) => {
    const selectedCategory = event.target.value;
    const station = stationMapping[selectedCategory];
    await setMonitor("Station", station);
    setSelectedStation(selectedCategory);
  };

  const handlesetDelay = async (newValue) => {
    if (typeof newValue === "function") {
      // ใช้ callback function ใน setValue
      setValue((prevValue) => {
        const updatedValue = newValue(prevValue);
        setMonitor("delay", updatedValue); // อัปเดต delay ใน monitor
        return updatedValue;
      });
    } else {
      // ใช้ค่าที่ส่งตรงมา
      setValue(newValue);
      await setMonitor("delay", newValue); // อัปเดต delay ใน monitor
    }
  };

  const getCellStyle = (dirID) => {
    if (selectedContestant === "") {
      return {};
    }
    if (dataMonitor[dirID] === selectedContestant) {
      return { backgroundColor: "#28a745", color: "white" };
    } else {
      return { backgroundColor: "#dc3545", color: "white" };
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4 fw-bold">
        Monitoring System Status
      </h2>

      {/* Section เลือกหมวดและนางงาม */}
      <div className="row mb-4 align-items-center g-3">
        {/* Dropdown เลือกหมวด */}
        <div className="col-md-4">
          <div className="form-group">
            <select
              id="categoryDropdown"
              className="form-select form-select-lg shadow-sm"
              value={selectedStation}
              onChange={handlesetStation}
            >
              <option value="">-- เลือกหมวด --</option>
              {Object.keys(stationMapping).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Dropdown เลือก Contestant */}
        <div className="col-md-4">
          <div className="dropdown w-100">
            <button
              className="btn btn-secondary dropdown-toggle w-100 shadow-sm"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              หมายเลขนางงาม: {selectedContestant || "ค้นหา..."}
            </button>
            <ul
              className="dropdown-menu p-3"
              aria-labelledby="dropdownMenuButton"
              style={{ minWidth: "100%" }}
            >
              {/* Search Bar */}
              <li className="mb-2">
                <input
                  type="text"
                  className="form-control shadow-sm"
                  placeholder="ค้นหานางงาม..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {/* Dropdown items */}
              <div
                style={{ maxHeight: "200px", overflowY: "auto" }}
                className="list-group"
              >
                {filteredContestants.length > 0 ? (
                  filteredContestants.map((contestant) => (
                    <button
                      key={contestant.ID}
                      className="list-group-item list-group-item-action shadow-sm"
                      onClick={() => handleContestantChange(contestant.ID)}
                    >
                      หมายเลข: {contestant.ID}
                    </button>
                  ))
                ) : (
                  <li className="text-muted text-center">ไม่พบนางงาม</li>
                )}
              </div>
            </ul>
          </div>
        </div>

        {/* ปุ่ม Set Instruction และ Set Dashboard */}
        <div className="col-md-4">
          <div className="btn-group w-100 shadow-sm">
            <button
              className="btn btn-warning btn-lg fw-bold w-50"
              onClick={handleInstructionClick}
            >
              Set Instruction
            </button>
            <button
              className="btn btn-primary btn-lg fw-bold w-50"
              onClick={handleDashboardClick}
            >
              Set Dashboard
            </button>
          </div>
        </div>

        <div className="col-md-4 mt-3">
          <div className="input-group">
            <button
              className="btn btn-danger fw-bold"
              onClick={() =>
                handlesetDelay((prevValue) => Math.max(prevValue - 1, 1))
              }
            >
              -
            </button>
            <input
              type="number"
              className="form-control text-center fw-bold"
              value={value}
              onChange={(e) => {
                const newValue = Number(e.target.value);
                handlesetDelay(Math.max(newValue, 1)); // ไม่ให้ค่าต่ำกว่า 0
              }}
            />
            <button
              className="btn btn-success fw-bold"
              onClick={() => handlesetDelay(value + 1)}
            >
              +
            </button>
          </div>
        </div>
      </div>

      {/* ตารางสถานะ Monitoring */}
      <div className="table-responsive">
        <table className="table table-bordered shadow-sm rounded">
          <thead className="table-dark">
            <tr>
              <th className="text-center fw-bold">Director ID</th>
              <th className="text-center fw-bold">Contestant</th>
            </tr>
          </thead>
          <tbody>
            {director
              .filter((dir) => dir.ID !== "A001")
              .map((dir) => (
                <tr key={dir.ID}>
                  <td className="text-center fw-bold">{dir.ID}</td>
                  <td
                    style={getCellStyle(dir.ID)}
                    className="text-center fw-bold"
                  >
                    {dataMonitor[dir.ID] || ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Monitor;
