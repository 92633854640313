import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/ListConts.css";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./image/logo512.png";
import api from "./api";

const ListConts = () => {
  const [contestants, setContestants] = useState([]); // State to store contestant data
  const [stationData, setStationData] = useState({}); // State to store station data
  const [searchTerm, setSearchTerm] = useState("");
  const [dataMonitor, setAlldataMonitor] = useState({});
  const [isStopped, setIsStopped] = useState(false);
  const [timecountdown, setTimecountdown] = useState(1000);

  const [isTimer, setIsTimer] = useState(false);
  const [countdown, setCountdown] = useState(1);

  const location = useLocation();
  const email = location.state?.email; // Receive email from Home
  const username = location.state?.username; // Receive username from Home
  const DirectorID = location.state?.DirectorID;
  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredContestants = contestants.filter((contestant) =>
    contestant.ID ? contestant.ID.toString().includes(searchTerm) : false
  );

  useEffect(() => {
    let timerInterval;
    if (isTimer && countdown > 0) {
      timerInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000); // นับถอยหลังทีละ 1 วินาที
    }

    // หยุดนับถอยหลังเมื่อถึง 0
    if (countdown === 0) {
      setIsTimer(false);
      clearInterval(timerInterval);
    }

    // เคลียร์ interval เมื่อ component ถูก unmount
    return () => clearInterval(timerInterval);
  }, [isTimer, countdown]);

  // Fetch contestant data
  const getConts = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data); // Assuming response.data.data contains contestant list
    } catch (error) {
      console.error(
        "Login error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getStation = async (Contsid) => {
    try {
      const response = await api.post(
        `/getDirDocument/${Contsid}/${DirectorID}`
      );
      setStationData((prevData) => ({ ...prevData, [Contsid]: response.data }));
      // console.log(`station Data ${Contsid}: `, response.data);
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  const setMonitor = async (Contsid) => {
    try {
      const response = await api.post(`/setmonitor`, {
        userID: Contsid,
        DirID: DirectorID,
      });
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  const getAllmonitor = async () => {
    try {
      const response = await api.post("/getAllmonitor");
      setAlldataMonitor(response.data.data);
      console.log("Updated dataMonitor:", response.data.data);
    } catch (error) {
      console.error("Error fetching monitor data:", error);
    }
  };

  const setDelay = async () => {
    console.log("data monitor: ",dataMonitor["delay"])
    const dataDelay = dataMonitor["delay"];
    const timedelay = dataDelay * 1000;
    setCountdown(dataDelay);
    setTimecountdown(timedelay);
  }

  const Admin_control = async () => {
    console.log(dataMonitor); // ตรวจสอบข้อมูลทั้งหมดใน dataMonitor
    const monitorData = dataMonitor[DirectorID];
    console.log(monitorData);

    if (monitorData === "Dashboard") {
      console.log(`DirectorID: ${DirectorID} is currently set to Dashboard`);
      setDelay();
    } else {
      console.log(
        `DirectorID: ${DirectorID} is currently monitoring----- : ${monitorData} , isStopped ${isStopped}`
      );
      setIsStopped(true);

      setIsTimer(true);
      if (monitorData === "Dashboard") {
        setTimeout(() => {
          handleImagesClick();
        }, timecountdown);
      } else if (monitorData === "Instruction") {
        setTimeout(() => {
          handleAdmincontrolintruction();
        }, timecountdown);
      } else {
        setTimeout(() => {
          handleAdmincontrol(monitorData);
        }, timecountdown);
      }
    }
  };

  const handleAdmincontrol = (id) => {
    if (id) {
      navigate(`/vote/`, {
        state: { username, id: id, contestants, DirectorID },
      });
    }
  };

  useEffect(() => {
    if (!isStopped) {
      const intervalId = setInterval(() => {
        getAllmonitor();
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isStopped]);

  useEffect(() => {
    if (!isStopped && Object.keys(dataMonitor).length !== 0 && DirectorID) {
      Admin_control();
    }
  }, [dataMonitor, DirectorID, isStopped]);

  useEffect(() => {
    console.log("isStopped: -----------------------------", isStopped);
    getConts(); // Call getConts when the component mounts
    setMonitor("Dashboard");
  }, []);

  useEffect(() => {
    if (contestants.length > 0) {
      contestants.forEach((contestant) => {
        getStation(contestant.ID); // Fetch station data for each contestant
      });
    }
  }, [contestants]);

  const handleSelectContestant = (id) => {
    setMonitor(id);
    navigate(`/vote/`, {
      state: { email, username, id, contestants, DirectorID },
    });
  };

  const handleImagesClick = () => {
    navigate("/list-conts", { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  const handleAdmincontrolintruction = () => {
    navigate(`/home-comming`, { state: { email, username, DirectorID } }); // ส่ง userName ไปยัง ListConts
  };

  return (
    <div className="container mt-3">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <span className="btn btn-primary" onClick={handleImagesClick}>
            <i className="bi bi-person-circle"></i> Dashboard
          </span>
          <span>
            <img
              src={logo}
              alt="Logo"
              className="img-fluid"
              onClick={handleImagesClick}
              style={{ maxWidth: "50px", maxHeight: "50px" }}
            />
          </span>
          {isTimer && (
            <div className="text-white me-3">
              <i className="fa-solid fa-hourglass-half me-2"></i> นางงามถัดไป:{" "}
              {countdown} วินาที
            </div>
          )}
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              เลือกนางงามที่ต้องการประเมิน
            </button>
            <ul
              className="dropdown-menu p-2"
              aria-labelledby="dropdownMenuButton"
              style={{ minWidth: "200px" }}
            >
              {/* Search Bar */}
              <li>
                <input
                  type="text"
                  className="form-control"
                  placeholder="ค้นหานางงาม..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {/* Dropdown items */}
              {filteredContestants.map((contestants) => (
                <li key={contestants.id}>
                  <button
                    className="dropdown-item"
                    onClick={() => handleSelectContestant(contestants.ID)}
                  >
                    {contestants.ID}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Table */}
      <div className="table-responsive mt-4">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">ลำดับ</th>
              <th scope="col">ชื่อนางงาม</th> {/* Align text left */}
              <th scope="col">
                หมวด 12 อ คิดสร้างสรรค์และ การตอบคำถามและทัศนคติ (ส่วนบุคคล)
              </th>
              <th scope="col">หมวดเดินแบบชุดราตรี</th>
            </tr>
          </thead>
          <tbody>
            {contestants.length > 0 ? (
              contestants.map((contestant) => {
                const contestantStationData = stationData[contestant.ID] || {};
                const documentNames = contestantStationData.documentNames || [];
                return (
                  <tr
                    key={contestant.ID}
                    onClick={() => handleSelectContestant(contestant.ID)}
                    style={{ cursor: "pointer" }}
                  >
                    <th scope="row">{contestant.ID}</th>
                    <td
                      className="text-start py-2"
                      style={{ paddingLeft: "50px" }}
                    >
                      {contestant.Name}
                    </td>{" "}
                    {/* Align text left */}
                    {/* การประเมินที่ 1 */}
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={documentNames.includes("Station1")}
                        readOnly
                        disabled
                        style={{
                          backgroundColor: documentNames.includes("Station1")
                            ? "green"
                            : "white",
                        }}
                      />
                    </td>
                    {/* การประเมินที่ 2 */}
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={documentNames.includes("Station2")}
                        readOnly
                        disabled
                        style={{
                          backgroundColor: documentNames.includes("Station2")
                            ? "green"
                            : "white",
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  ไม่มีข้อมูลนางงาม
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListConts;
