import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const ContBoardscore = () => {
  const [contestants, setContestants] = useState([]);
  const [scores, setScores] = useState({});

  const getConts = async () => {
    try {
      const response = await api.post("/getAllpublishDataConts");
      console.log("data:", response.data);
      setContestants(response.data.data);
    } catch (error) {
      console.error(
        "Login error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getEvaluationConts = async (data) => {
    console.log("data: ", data);
    try {
      const response = await api.post("/getdataListvote", {
        contID: data.ID,
      });

      // console.log("data response" ,response.data)

      const resultScore = response.data.Data.resultScore;
      console.log(resultScore);
      setScores((prevScores) => ({
        ...prevScores,
        [data.ID]: resultScore, // อัปเดตคะแนนสำหรับ ID นี้
      }));
    } catch (error) {
      console.error(
        "Error fetching evaluation data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    contestants.forEach((data) => {
      getEvaluationConts(data);
      //   console.log("datalist: ", scores);
    });
  }, [contestants]);

  useEffect(() => {
    getConts();
  }, []);

  const hundleRefresh = async () => {
    getConts();
    contestants.forEach((data) => {
      getEvaluationConts(data);
    
    });
  };

  return (
    <div className="container mt-5">
      {/* Header */}
      <div className="row mb-4">
        <div className="d-flex justify-content-center align-items-center">
          <h2 className="text-center mb-4 text-primary fw-bold">
            Contestants Dashboard
          </h2>
        </div>
      </div>

      {/* Main Content */}
      <div className="row">
        <div className="col-12">
          <div className="p-4 bg-light border rounded shadow-sm">
            {/* Header Section */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0 text-secondary">Contestants List</h5>
              <button className="btn btn-primary" onClick={hundleRefresh}>
                Refresh
              </button>
            </div>

            {/* Contestants */}
            {contestants.map((data, index) => (
              <div
                key={index}
                className="d-flex p-2 mb-3 border rounded bg-white shadow-sm align-items-center"
              >
                {/* Image Section */}
                <div
                  className="col-2 d-flex justify-content-center align-items-center"
                  style={{
                    padding: "0.5rem", // ลด padding รอบรูป
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "1 / 1", // กำหนดอัตราส่วน 1:1
                      overflow: "hidden",
                      borderRadius: "5%",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <img
                      src={require(`../image/uploads_misswellness/${data.URL}`)}
                      alt={data.Name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain", // ครอบพื้นที่
                      }}
                    />
                  </div>
                </div>

                {/* Info Section */}
                <div className="col-6 px-3 d-flex flex-column justify-content-start text-start">
                  {/* ลดระยะ padding ซ้ายขวา */}
                  <div className="mb-1">
                    <strong className="text-secondary">Contestant ID:</strong>{" "}
                    <span className="text-dark">{data.ID}</span>
                  </div>
                  <div className="mb-1">
                    <strong className="text-secondary">Name:</strong>{" "}
                    <span className="text-dark">{data.Name}</span>
                  </div>
                  <div className="mb-1">
                    <strong className="text-secondary">Nickname:</strong>{" "}
                    <span className="text-dark">{data.Nickname}</span>
                  </div>
                  <div>
                    <strong className="text-secondary">Age:</strong>{" "}
                    <span className="text-dark">{data.Age}</span>
                  </div>
                </div>

                <div className="col-3 text-center py-2 ms-auto mt-3">
                  <strong className="text-success mb-2 d-block">
                    Total Score:{" "}
                  </strong>
                  <span className="display-6 fw-bold d-block mb-2">
                    {scores[data.ID] !== undefined ? scores[data.ID] : "0"}
                  </span>
                  <small className="text-muted d-block">points</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContBoardscore;
